<template>
  <div id="root" @click="goHome()">
    404
  </div>
</template>

<script>

export default {
  components: {
  },
  methods: {
    goHome () {
      this.$router.push('/home')
    }
  }
}
</script>

<style>
#root {
}
</style>
